import Image from "next/image"
import followingReportsPic from "@/assets/images/following-reports.png"
import { Button } from "antd"
import { Routes } from "@/services/routes"
import { useRouter } from "next-nprogress-bar"

const FollowingReports = () => {
  const router = useRouter()

  return (
    <div className="following-report">
      <div className="block-title">Get The Following Reports Instantly</div>

      <div className="block-content following-report__content">
        <Image
          className="following-report__image"
          src={followingReportsPic}
          alt=""
          quality={100}
        />

        <div className="following-report__description">
          <div className="following-report__title block-title">
            Return on Ad Spend (ROAS)
          </div>
          <div className="following-report__description-text block-description">
            Automated reporting tools can connect to a variety of data sources,
            including databases, spreadsheets, and social media.
          </div>
          <Button
            className="button following-report__button"
            onClick={() => {
              router.push(Routes.office.user.login)
            }}
          >
            Get Started Now
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FollowingReports
