"use client"

import { Layout } from "antd"
import React from "react"
import AboutBlock from "@/application/landing/components/main-page/content/AboutBlock"
import AutomatedReportsBlock from "@/application/landing/components/main-page/content/AutomatedReportsBlock"
import DataDrivenSection from "@/application/landing/components/main-page/content/data-driven/DataDrivenSection"
import BlogSection from "@/application/landing/components/main-page/content/blog/BlogSection"
import { BlogArticle } from "@/application/entities/blog"
import FeaturesSection from "@/application/landing/components/main-page/content/features/FeaturesSection"
import { PricePlan, PricePlanFeature } from "@/application/entities/prices"
import PriceSection from "@/application/landing/components/main-page/content/price/PriceSection"
import ScheduleDemoButton from "@/application/landing/components/common/ScheduleDemoButton"
import Header from "@/application/landing/components/Header"

const { Content } = Layout

interface IProps {
  articles: BlogArticle[]
  pricePlans: PricePlan[]
  features: PricePlanFeature[]
}

const LandingContent = ({ articles, pricePlans, features }: IProps) => {
  return (
    <Content className="main-page__content content">
      <Header />

      <ScheduleDemoButton className="mt-8" />

      <AboutBlock />

      <AutomatedReportsBlock />

      <DataDrivenSection>
        <DataDrivenSection.Title />
        <DataDrivenSection.FollowingReports />
        <DataDrivenSection.CPA />
      </DataDrivenSection>

      <FeaturesSection>
        <FeaturesSection.Title />
        <FeaturesSection.Content />
      </FeaturesSection>

      {pricePlans.length > 0 && (
        <PriceSection>
          <PriceSection.Title />
          <PriceSection.Content
            mode="landing"
            pricePlans={pricePlans}
            features={features}
          />
        </PriceSection>
      )}

      {articles.length > 0 && (
        <BlogSection articles={articles}>
          <BlogSection.Title />
          <BlogSection.Content />
        </BlogSection>
      )}

      {/*<FAQSection>*/}
      {/*  <FAQSection.Title />*/}
      {/*  <FAQSection.QA />*/}
      {/*</FAQSection>*/}
    </Content>
  )
}

export default LandingContent
