import { useRouter } from "next-nprogress-bar"
import { Routes } from "@/services/routes"
import { Button } from "antd"

const Title = () => {
  const router = useRouter()

  const goBlog = () => {
    router.push(Routes.landing.prices.page)
  }

  return (
    <div className="section-title mt-[78px]">
      <Button className="button outlined" onClick={goBlog}>
        Pricing Plan
      </Button>
      <h2 className="section-title__text">Try For Free</h2>
    </div>
  )
}

export default Title
