import Image from "next/image"
import cpaPic from "@/assets/images/cpa.png"
import { Button } from "antd"
import { Routes } from "@/services/routes"
import { useRouter } from "next-nprogress-bar"

const CPA = () => {
  const router = useRouter()

  return (
    <div className="cpa">
      <div className="cpa__title">
        <div className="cpa__title__text block-title">
          Cost Per Acquisition (CPA)
        </div>
        <div className="cpa__description block-description">
          Automated reporting tools can connect to a variety of data sources,
          including databases, spreadsheets, and social media.
        </div>
        <Button
          className="cpa__button button"
          onClick={() => {
            router.push(Routes.office.user.login)
          }}
        >
          Get Started Now
        </Button>

        <div className="cpa__biz block-title">Biz Report Card</div>

        <div className="cpa__month block-title">Month over Month (MoM)</div>

        <div className="cpa__week block-title">Week over Week (WoW)</div>
      </div>

      <div className="block-content">
        <Image className="cpa__image" src={cpaPic} quality={100} alt="CPA" />
      </div>
    </div>
  )
}

export default CPA
