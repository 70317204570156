import Title from "./Title"
import React from "react"
import PricePlans from "@/application/prices/components/PricePlans"

interface ICompound {
  Title: typeof Title
  Content: typeof PricePlans
}

const PriceSection: React.FC<React.PropsWithChildren> & ICompound = ({
  children,
}) => {
  return <div className="prices">{children}</div>
}

PriceSection.Title = Title
PriceSection.Content = PricePlans

export default PriceSection
