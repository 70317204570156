"use client"

import { Routes } from "@/services/routes"
import { Button } from "antd"
import { useRouter } from "next-nprogress-bar"

const Title = () => {
  const router = useRouter()

  const goBlog = () => {
    router.push(Routes.landing.blog.page)
  }

  return (
    <div className="blog-title">
      <Button className="blog-title__button button outlined" onClick={goBlog}>
        Our Blogs
      </Button>
      <h2 className="blog-title__title section-title__text">Our Blogs</h2>
    </div>
  )
}

export default Title
