const Title = () => {
  return (
    <div className="data-driven-title">
      {/*<Button className="data-driven-title__button button outlined">About Us</Button>*/}
      <h2 className="data-driven-title__title section-title__text">
        Make data-driven <span className="marketing">marketing</span> decisions
        with ease
      </h2>
    </div>
  )
}

export default Title
