import { client } from "@/services/api"
import { Routes } from "@/services/routes"
import { BlogArticle } from "@/application/entities/blog"
import { action, makeObservable, observable } from "mobx"

export class BlogStore {
  @observable
  articles: BlogArticle[]

  static make() {
    return new BlogStore()
  }

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setArticles(articles: BlogArticle[]) {
    this.articles = articles
  }

  static async loadArticles() {
    try {
      const response = await client.get<BlogArticle[]>(Routes.api.blog.list, {
        withoutToast: true,
      } as any)
      return response.data
    } catch (e) {
      /**/
    }
    return []
  }

  static async loadArticle(slug: string) {
    const response = await client.get<BlogArticle>(
      Routes.api.blog.get.replace(":slug", slug),
    )
    return response.data
  }
}
