import Image from "next/image"
import Link from "next/link"
import classnames from "classnames"
import arrowLinkPic from "@/assets/images/arrow-link.svg"

interface IProps {
  className?: string
  title?: any
  href: any
}

const SiteLink: React.FC<React.PropsWithChildren & IProps> = (props) => {
  return (
    <Link
      className={classnames("link", props.className || {})}
      title={props.title}
      href={props.href}
    >
      {props.children}
      <Image className="link__arrow" src={arrowLinkPic} alt="" />
    </Link>
  )
}

export default SiteLink
