import Title from "@/application/landing/components/main-page/content/data-driven/Title"
import FollowingReports from "@/application/landing/components/main-page/content/data-driven/FollowingReports"
import CPA from "@/application/landing/components/main-page/content/data-driven/CPA"

interface ICompound {
  Title: typeof Title
  FollowingReports: typeof FollowingReports
  CPA: typeof CPA
}

const DataDrivenSection: React.FC<React.PropsWithChildren> & ICompound = ({
  children,
}) => {
  return <div className="data-driven">{children}</div>
}

DataDrivenSection.Title = Title
DataDrivenSection.FollowingReports = FollowingReports
DataDrivenSection.CPA = CPA

export default DataDrivenSection
