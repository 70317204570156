"use client"

import React, { useContext } from "react"
import { BlogStore } from "@/application/landing/blog-store"
import { BlogArticle } from "@/application/entities/blog"
import { useLocalObservable } from "mobx-react"

const services = { store: BlogStore.make() }

const BlogContext = React.createContext(services)

interface IProps {
  articles: BlogArticle[]
}

const BlogServices: React.FC<React.PropsWithChildren & IProps> = ({
  children,
  articles,
}) => {
  const providerServices = useLocalObservable(() => {
    services.store.setArticles(articles)
    return services
  })

  return (
    <BlogContext.Provider value={providerServices}>
      {children}
    </BlogContext.Provider>
  )
}

export const useBlog = () => {
  return useContext(BlogContext)
}

export default BlogServices
