import Image from "next/image"
import { Button } from "antd"
import automatedReportsChannelsPic from "@/assets/images/automated-reports-channels.png"
import { Routes } from "@/services/routes"
import { useRouter } from "next-nprogress-bar"

const AutomatedReportsBlock = () => {
  const router = useRouter()

  return (
    <div className="automated-reports">
      <div className="automated-reports__left">
        <div className="block-title">Get automated insightful reports</div>
        <div className="block-description">
          Automated reporting tools can connect to a variety of data sources,
          including databases, spreadsheets, and social media.
        </div>
        <Button
          className="button"
          onClick={() => {
            router.push(Routes.office.user.login)
          }}
        >
          Get Started Now
        </Button>
      </div>
      <div className="automated-reports__right">
        <Image
          className="automated-reports__right__channels-pic"
          src={automatedReportsChannelsPic}
          alt="Connect Your Channels"
          width={786.5}
          height={442}
        />
      </div>
    </div>
  )
}

export default AutomatedReportsBlock
