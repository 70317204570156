"use client"

import BlogArticle from "@/application/landing/components/main-page/content/blog/BlogArticleContainer"
import { observer } from "mobx-react"
import { useBlog } from "@/application/landing/components/main-page/content/blog/BlogServices"

const Content = () => {
  const { store } = useBlog()

  return (
    <div className="blog-content">
      {store.articles.map((article, i) => (
        <BlogArticle
          key={i}
          image={article.image}
          date={article.date_published}
          title={article.title}
          href={article.href}
        />
      ))}
    </div>
  )
}

export default observer(Content)
