import Title from "@/application/landing/components/main-page/content/blog/Title"
import Content from "@/application/landing/components/main-page/content/blog/Content"
import BlogServices from "@/application/landing/components/main-page/content/blog/BlogServices"
import { BlogArticle } from "@/application/entities/blog"

interface ICompound {
  Title: typeof Title
  Content: typeof Content
}

interface IProps {
  articles: BlogArticle[]
}

const BlogSection: React.FC<React.PropsWithChildren & IProps> & ICompound = ({
  children,
  articles,
}) => {
  return (
    <BlogServices articles={articles}>
      <div className="blog">{children}</div>
    </BlogServices>
  )
}

BlogSection.Title = Title
BlogSection.Content = Content

export default BlogSection
