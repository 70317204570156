"use client"

import { StaticImport } from "next/dist/shared/lib/get-img-props"
import Link from "@/ui/components/Link"
import Image from "next/image"

interface IProps {
  image: StaticImport | any
  date: string
  title: string
  href: string
}

const BlogArticleContainer = ({ image, date, title, href }: IProps) => {
  return (
    <div className="blog-card">
      <div className="blog-card__image">
        <Image src={image} alt="" width={305} height={152} />
      </div>
      <div className="blog-card__content-wrapper">
        <span className="blog-card__date">{date}</span>
        <span className="blog-card__content">{title}</span>
        <Link className="blog-card__link" href={href}>
          Read More
        </Link>
      </div>
    </div>
  )
}

export default BlogArticleContainer
